<template>
  <b-overlay :show="show" rounded="sm" class="pb-2">
    <b-row :class="this.questionnaireCategory != 'My mental health and how I feel' ? 'match-height' : ''">
      <!-- Average Circle chart -->
      <b-col lg="4">
          <average-score-circle-chart 
            :bar="data.barChart" 
            :chartColor="cColor" 
            :barColors="bColors" 
            :barCategories="barCategories"
            :title="`Your ${subcategory} ${scoreType}`" 
            type="details-circle-chart" 
            :data="data.circleChart" 
            :message="scoreInfo" 
            :professional="professional" 
            :subcategory="subcategory" 
            :questionnaireCategory="questionnaireCategory" />
      </b-col>
    
      <!-- Users Messages -->
      <b-col lg="8">
        <users-messages :id="parseInt(questionnaireId)" />
      </b-col>
    </b-row>

    <!-- Questions and answers -->
    <b-row class="match-height">
      <b-col lg="12">
        <b-card-code :title="`${subcategory} details`">
          <b-list-group v-for="question in questions" :key="question.id">
            <b-list-group-item v-if="question.question_type_id != 3 && question.question_type_id != 4 && question.questionnaireresults.length >0" class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>
                  {{ question.question_text }}
                </h5>
              </div>
              <b-card-text>
                <small
                  v-for="(questionnaireresults,
                  index) in question.questionnaireresults"
                  :key="index"
                >
                  <div>
                    <span class="color-mhc-light font-weight-bold">Answer: </span> 
                    <span v-if="question.is_scale == 1" class="color-mhc-dark font-weight-bold">{{ questionnaireresults.answer.answer_text | changeScaleText }}</span>
                    <span v-else class="color-mhc-dark font-weight-bold">{{ questionnaireresults.answer.answer_text }}</span>
                  </div>
                </small>
              </b-card-text>
            </b-list-group-item>
            <b-list-group-item v-else-if="question.question_type_id == 3 || question.question_type_id == 4" class="flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5>
                  {{ question.question_text }}
                </h5>
              </div>
              <b-card-text>
                Options: {{ question.answers | getAnswers }}
                <div v-if="question.questionnaireresults.length > 0">
                  <small
                    v-for="(questionnaireresults,
                    index) in question.questionnaireresults"
                    :key="index"
                  >
                    <div>
                      <span class="color-mhc-dark font-weight-bold">{{index + 1}}. </span> 
                      <span v-if="question.is_scale == 1" class="color-mhc-dark font-weight-bold">{{ questionnaireresults.answer.answer_text | changeScaleText }}</span>
                      <span v-else class="color-mhc-dark font-weight-bold">{{ questionnaireresults.answer.answer_text }}</span>
                    </div>
                  </small>
                </div>
                <div v-else>
                  <small>
                      <span class="color-mhc-dark font-weight-bold">None of the above</span>
                  </small>
                </div>
              </b-card-text>
            </b-list-group-item>
          </b-list-group>
        </b-card-code>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import moment from "moment";
import { BRow, BCol, BOverlay, BListGroup, BListGroupItem, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import AverageScoreCircleChart from '../../components/my-hub/AverageScoreCircleChart.vue'
import UsersMessages from '../../components/questionnaires/UsersMessages.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BCardCode,
    BListGroup,
    BListGroupItem,
    BCardText,

    AverageScoreCircleChart,
    UsersMessages,
  },
  data() {
    return {
      show: true,
      questionnaireId: this.$route.params.id,
      questionnaireType: "Standard",
      questionnaireCategory: "",
      barCategories: [],
      questions: [],
      questionnaireData: '',
      subcategory: '',
      score: 0,
      scoreInfo: {},
      completedOnDate: '',
      professional: null,
      bColors: [],
      cColor: "",
      scoreType: "",
      data: {
        barChart: [],
        circleChart: {
          series: [20, 80],
        },
        reminders: {
          reminderData: [],
        },
      },
    };
  },
  filters: {
    changeScaleText: function (value) {
      if (!value) return ''
      value = value.replace('Scale ', '')
      value = value + ' out of 10'
      return value
    },
    getAnswers: function (answersInJson) {
      let answers = [];
      let i = 0;
      Object.keys(answersInJson).forEach(function(key) {
        answers.push(answersInJson[i]['answer_text']);
        i++;
      });
      return answers.join(", ");;
    }
  },
  methods: {
    getData() {

    // get answers to question for this questionnaire submit
    this.$http.get("/api/auth/questionnaire/result/" + this.questionnaireId)
      .then((res) => {
        // this is used when the user clicks the back button in the breadcrumb
        // so that way we know where to take him..
        localStorage.setItem("questionnaire_took_id", res.data.id); 
        if(res != undefined) {
          this.completedOnDate = moment(res.data.created_at).format("DD/MM/YYYY")

          this.questionnaireData = res.data.questionnaire;
          this.questions = this.questionnaireData.questions;
          this.subcategory = this.questionnaireData.subcategory.name
          this.professional = this.questionnaireData.professional
          this.questionnaireCategory = res.data.questionnaire.subcategory.questionnairecategory.name          

          // get the score for this questionnaire submit
          this.$http.get(
            "/api/auth/questionnaire/score/" + this.questionnaireId
          )
          .then((res) => {
            if(res != undefined) {
              this.scoreInfo = res.data
              
              // Fill BarChart values
              if(this.questionnaireCategory == "My mental health and my life situation(s)") {        
                let biological = res.data.sections['Section B - Biological question']
                let psychological = res.data.sections['Section B - Psychological question']
                let social = res.data.sections['Section B - Social question']
                this.data.barChart = [biological.section_score, psychological.section_score, social.section_score]
                this.bColors = [biological.section_score_color, psychological.section_score_color, social.section_score_color]
                this.barCategories = ["Biological", "Psychological", "Social"]
                this.scoreType = "Trait Score";
              } else if(this.questionnaireCategory == "My mental health and my job/career") {
                let before_work = res.data.sections['Before Work']
                let at_work = res.data.sections['At Work']
                let after_work = res.data.sections['After Work']
                this.data.barChart = [before_work.section_score, at_work.section_score, after_work.section_score]
                this.bColors = [before_work.section_score_color, at_work.section_score_color, after_work.section_score_color]
                this.barCategories = ["Before Work", "At Work", "After Work"]
                this.scoreType = "Trait Score";
              } else if(this.questionnaireCategory == "My mental wellbeing") {
                let calm = res.data.sections['Calm & Inner peace']
                let joy = res.data.sections['Joy and Contentment']
                let adaptability = res.data.sections['Adaptability']
                let rest = res.data.sections['Rest & Relaxation']
                let health = res.data.sections['Health & Lifestyle']
                let relationships = res.data.sections['Relationships & Connections']
                let satisfaction = res.data.sections['Satisfaction & Fulfillment']
                
                this.data.barChart = [calm.section_score, joy.section_score, adaptability.section_score, rest.section_score, health.section_score, relationships.section_score, satisfaction.section_score]
                this.bColors = [calm.section_score_color, joy.section_score_color, adaptability.section_score_color, rest.section_score_color, health.section_score_color, relationships.section_score_color, satisfaction.section_score_color]
                this.barCategories = ['Calm & Inner peace','Joy and Contentment', 'Adaptability', 'Rest & Relaxation', 'Health & Lifestyle', 'Relationships & Connections', 'Satisfaction & Fulfillment']              
                this.scoreType = "Trait Score";
              } else {
                this.scoreType = "Index score";
              }

              this.scoreInfo['completedOnDate'] = this.completedOnDate
              this.section_score = res.data["Questionnaire Score"].section_score;
              this.data.circleChart.series = [this.section_score, (10 - this.section_score)].reverse();
              this.cColor = res.data["Questionnaire Score"].section_score_color;

              if(this.section_score > 9.7) {
                this.data.circleChart.series = [10, 0.3].reverse();
              }

              if(this.section_score == 0) {
                this.data.circleChart.series = [10, 0.3];
              }

              this.show = false;
            }
          })
          .catch((error) => {
            console.log(error);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });

        }

      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    $route (to, from){
      this.getData()
      // Do reload page to get fresh data
      this.$router.go(this.$router.currentRoute)
    },
} 
};
</script>

<style scoped>
.list-group-item {
  border: 0px;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125);
}
</style>
